.toastContainer {
  position: fixed;
  z-index: 30;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 388px;
  transition-property: bottom;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none; }

.container {
  position: absolute;
  display: flex;
  width: 100%;
  padding: 24px; }

.bottom {
  bottom: 0;
  right: 0;
  flex-direction: column-reverse; }

.top {
  top: 0;
  right: 0;
  flex-direction: column-reverse; }

.enter {
  transform: translateX(388px); }

.enterActive {
  transform: translateX(0);
  transition-property: transform;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); }

.exit {
  opacity: 1; }

.exitActive {
  opacity: 0;
  transition-property: opacity;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); }

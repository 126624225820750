.assetPickerFolderItem {
  display: flex;
  list-style: none;
  padding: 8px;
  border-radius: 4px;
  margin: 4px 0;
  outline: none;
  border: transparent solid 2px; }

.assetColumn {
  flex: 1 1 auto;
  align-items: center;
  overflow: hidden; }

.endControlColumn {
  color: inherit;
  align-items: center;
  padding-left: 16px; }

.isActive,
.isSelected {
  cursor: pointer; }

.isSelected,
.isSelected:hover {
  background-color: #E5F7E1; }

.isSelected:focus {
  outline: none;
  border: rgba(30, 140, 13, 0.3) solid 2px; }

.isActive:hover {
  background-color: #FAFAFC; }

.isActive:focus {
  border: rgba(11, 11, 13, 0.1) solid 2px; }

.isDisabled {
  color: inherit; }

.dropdownMenu {
  min-width: 186px;
  list-style: none;
  margin: 0;
  padding: 8px 0; }

.dropdownContentGroup {
  border-bottom: 1px solid #EBEBED; }

.insertMode {
  border-bottom: 0; }

.insertMode .dropdownMenu {
  padding: 8px 0 0; }

.dropdownContentGroup:last-child {
  border: 0 none; }

.dropdownHeader {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.34px;
  font-weight: normal;
  color: #777778;
  padding: 12px 8px 2px 12px;
  margin: 0;
  font-family: "Aktiv Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .dropdownHeader {
      font-size: 14px;
      letter-spacing: 0.27px; } }

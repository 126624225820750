.calendarMonth {
  margin: 0 16px 16px; }

.weekDay {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0;
  font-weight: bold;
  color: #777778;
  padding: 12px 0; }
  @media (max-width: 767px) {
    .weekDay {
      font-size: 14px;
      letter-spacing: 0.17; } }

.day {
  margin: 0;
  width: auto;
  overflow: visible;
  cursor: pointer;
  background: none;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  appearance: none;
  /* Remove excess padding and border in Firefox 4+ */
  margin: 2px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  text-align: center;
  vertical-align: middle;
  border: 0; }
  .day::-moz-focus-inner {
    border: 0;
    padding: 0; }
  .day.isToday {
    color: #32A31A;
    font-weight: bold; }
  .day.isSelected {
    background-color: #32A31A;
    color: #FFF; }
  .day.isSelected:hover {
    background-color: #71CC52; }
  .day:disabled {
    cursor: default;
    color: #A7A7AA; }
  .day:not(:disabled):hover {
    background-color: #EBEBED; }

.datepicker {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.11px;
  font-weight: normal;
  text-align: center;
  user-select: none;
  background-color: #FFF;
  border-radius: 4px;
  border: 1px solid #EBEBED;
  box-shadow: 0 2px 4px 0 rgba(192, 196, 204, 0.5), 0 12px 32px -4px rgba(192, 196, 204, 0.5);
  overflow: auto;
  max-height: inherit; }

.header {
  display: flex;
  align-items: center;
  padding: 12px; }

.title {
  flex: 1 0 auto;
  color: #2C2C2E;
  font-weight: bold; }

.changeMonth {
  flex: 0 0 auto; }

.formattedContentBlock h1 {
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.08px;
  font-weight: normal;
  font-family: "Lexia", "Aktiv Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  color: #168208;
  margin: 24px 0; }
  @media (max-width: 767px) {
    .formattedContentBlock h1 {
      font-size: 32px;
      line-height: 36px;
      letter-spacing: 0.07px; } }

.formattedContentBlock h2 {
  font-size: 25px;
  line-height: 28px;
  letter-spacing: -0.23px;
  font-weight: bold;
  margin: 24px 0; }
  @media (max-width: 767px) {
    .formattedContentBlock h2 {
      font-size: 22px;
      letter-spacing: -0.3px; } }

.formattedContentBlock h3 {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.09px;
  font-weight: bold;
  margin: 24px 0; }
  @media (max-width: 767px) {
    .formattedContentBlock h3 {
      line-height: 24px; } }

.formattedContentBlock h4 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.11px;
  font-weight: bold;
  margin: 4px 0; }

.formattedContentBlock h5 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.11px;
  font-weight: normal;
  margin: 4px 0; }

.formattedContentBlock h6 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.11px;
  font-weight: normal;
  margin: 4px 0; }

.formattedContentBlock p {
  margin: 16px 0; }

.formattedContentBlock em {
  font-style: italic; }

.formattedContentBlock strong {
  font-weight: bold; }

.formattedContentBlock small {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.34px;
  font-weight: normal; }
  @media (max-width: 767px) {
    .formattedContentBlock small {
      font-size: 14px;
      letter-spacing: 0.27px; } }

.formattedContentBlock u {
  text-decoration: underline; }

.formattedContentBlock a {
  font-weight: bold;
  color: #1E8C0D;
  text-decoration: none;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  transition: color 165ms ease-out; }
  .formattedContentBlock a:hover, .formattedContentBlock a:focus-visible {
    text-decoration: underline; }
  .formattedContentBlock a:active {
    opacity: 0.8; }
  .formattedContentBlock a:focus:not(:focus-visible) {
    outline: none; }

.formattedContentBlock ul {
  list-style: disc; }

.formattedContentBlock ol {
  list-style: decimal; }

.formattedContentBlock ul,
.formattedContentBlock ol {
  padding-left: 32px;
  margin-bottom: 24px; }
  .formattedContentBlock ul ol,
  .formattedContentBlock ul ul,
  .formattedContentBlock ol ol,
  .formattedContentBlock ol ul {
    margin-bottom: 0; }

.formattedContentBlock pre,
.formattedContentBlock code {
  background: #EBEBED;
  color: #0B0B0D;
  border: 1px solid #EBEBED;
  border-radius: 4px;
  padding: 16px;
  font-family: monospace; }

.formattedContentBlock code {
  padding: 2px; }

.formattedContentBlock pre > code {
  background: transparent;
  padding: 0; }

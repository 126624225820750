.toastContent {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 30px; }

.icon {
  margin-right: 12px; }

.text {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0;
  font-weight: bold;
  flex: 1 1 auto;
  margin-right: 12px;
  word-break: break-word; }
  @media (max-width: 767px) {
    .text {
      font-size: 14px;
      letter-spacing: 0.17; } }

.action {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0;
  font-weight: bold; }
  @media (max-width: 767px) {
    .action {
      font-size: 14px;
      letter-spacing: 0.17; } }

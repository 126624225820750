.dropdownContent {
  background-color: #FFF;
  border-radius: 4px;
  border: 1px solid #EBEBED;
  box-sizing: border-box;
  min-width: 256px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 16px 32px -4px rgba(0, 0, 0, 0.1);
  max-height: inherit;
  max-width: inherit;
  overflow: auto;
  animation-duration: 100ms;
  animation-timing-function: cubic-bezier(0.18, 0.8, 0.44, 1);
  animation-name: topSlide; }
  .dropdownContent .isActiveFocus {
    background-color: #EBEBED;
    outline: none; }

:global(.TopLeft) .dropdownContent,
:global(.TopRight) .dropdownContent {
  animation-name: bottomSlide; }

.dropdownContent[data-ace-oversized],
.isOverSized {
  overflow-y: scroll; }

@keyframes topSlide {
  0% {
    transform: translate(0, -20px);
    opacity: 0; }
  100% {
    transform: translate(0, 0);
    opacity: 1; } }

@keyframes bottomSlide {
  0% {
    transform: translate(0, 20px);
    opacity: 0; }
  100% {
    transform: translate(0, 0);
    opacity: 1; } }

.header {
  font-size: 25px;
  line-height: 28px;
  letter-spacing: -0.23px;
  font-weight: bold;
  color: #57575A;
  margin: 0; }
  @media (max-width: 767px) {
    .header {
      font-size: 22px;
      letter-spacing: -0.3px; } }

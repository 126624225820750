.toast {
  width: 100%;
  background-color: #FAFAFC;
  color: #57575A;
  border-radius: 4px;
  border: 2px solid #D4D4D7;
  padding: 8px 12px;
  box-shadow: 0 8px 16px -4px rgba(0, 0, 0, 0.1);
  pointer-events: auto;
  margin-bottom: 8px; }
  .toast:first-child {
    margin-bottom: 0; }
  .toast:last-child {
    margin-top: 0; }

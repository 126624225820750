.appNavigation {
  display: flex;
  justify-content: space-between;
  color: #A7A7AA;
  background-color: #1e272e; }

.productSwitcher {
  display: flex;
  align-items: center;
  height: 48px; }

.productSwitcherAnsaradaLogomark {
  margin: 0 16px; }

.productSwitcherActiveProductName {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.11px;
  font-weight: normal;
  margin-right: 24px;
  font-family: "Lexia", "Aktiv Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  color: #FFF; }

.appNavigationKeyLandmarks {
  display: flex;
  flex: 1;
  align-items: center; }
  .appNavigationKeyLandmarks::before {
    content: '';
    margin-right: 24px;
    height: 32px;
    border: 0.5px solid #A7A7AA; }
  @media (max-width: 767px) {
    .appNavigationKeyLandmarks {
      display: none; } }

.keyLandmarks {
  display: flex; }

.keyLandmark {
  margin-right: 24px; }
  .keyLandmark:hover {
    color: #FFF; }

/* Todo: Remove this style override */
.keyLandmarkClickable {
  line-height: 48px;
  transition-duration: 0s;
  -webkit-font-smoothing: unset; }
  .keyLandmarkClickable:hover {
    text-decoration: none; }

.headingKeyLandmarks {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.appNavigationLandmarks {
  margin-right: 16px; }

.productNavigator {
  margin: 0;
  width: auto;
  overflow: visible;
  cursor: pointer;
  background: none;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  appearance: none;
  /* Remove excess padding and border in Firefox 4+ */
  display: flex;
  align-items: center;
  height: 48px;
  border: 0;
  padding: 0;
  font-weight: bold;
  color: inherit; }
  .productNavigator::-moz-focus-inner {
    border: 0;
    padding: 0; }
  .productNavigator:focus:not(:focus-visible) {
    outline: 0; }
  @media (max-width: 767px) {
    .productNavigator:focus > .productNavigatorIconSmallScreen,
    .productNavigator:hover > .productNavigatorIconSmallScreen {
      color: #FFF; } }
  @media (min-width: 768px) {
    .productNavigator:focus, .productNavigator:hover {
      color: #FFF; }
    .productNavigator:focus > .productNavigatorIconLargeScreen,
    .productNavigator:hover > .productNavigatorIconLargeScreen {
      border-top: 5px solid #FFF; } }

@media (min-width: 768px) {
  .productNavigatorIconSmallScreen {
    display: none; } }

@media (max-width: 767px) {
  .productNavigatorUserFullName {
    display: none; } }

.productNavigatorIconLargeScreen {
  border-top: 5px solid #A7A7AA;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin: 2px 0 0 8px; }
  @media (max-width: 767px) {
    .productNavigatorIconLargeScreen {
      display: none; } }

.headingLandmarks {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

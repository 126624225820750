.dropdownAction {
  font-family: "Aktiv Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif !important; }

/* Overwrite ace css for links */
.link {
  font-family: "Aktiv Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif !important; }

.link:disabled {
  color: #A7A7AA !important; }

.link:not(:disabled):link, .link:not(:disabled):visited, .link:not(:disabled):hover, .link:not(:disabled):focus {
  color: #57575A !important;
  text-decoration: none; }

/* Need to move up the copy 1px to align with ace icon as icons don't use new grid */
.isIconText {
  margin-top: -1px;
  margin-bottom: 1px; }

@-moz-document url-prefix() {
  .isIconText {
    margin-top: 2px;
    margin-bottom: -2px; } }

.dropdownAction {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.11px;
  font-weight: normal; }

button.link {
  margin: 0;
  width: auto;
  overflow: visible;
  cursor: pointer;
  background: none;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  appearance: none;
  /* Remove excess padding and border in Firefox 4+ */
  line-height: 24px;
  width: 100%;
  text-align: left; }
  button.link::-moz-focus-inner {
    border: 0;
    padding: 0; }

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 2px 12px;
  border: 2px solid transparent;
  color: #57575A; }
  .link:disabled {
    cursor: default;
    color: #A7A7AA;
    pointer-events: none; }
  .link:not(:disabled):focus, .link:not(:disabled):hover {
    outline: none; }

.actionAnnotation {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.34px;
  font-weight: normal;
  padding-left: 16px;
  color: #777778; }
  @media (max-width: 767px) {
    .actionAnnotation {
      font-size: 14px;
      letter-spacing: 0.27px; } }

.actionText {
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }
  .actionText.isIconText {
    padding-left: 8px; }

.isSelected::after {
  content: '';
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAyMCAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTYgOEgwVjBWMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMS43OTM0NiA4LjU1NzYyKSByb3RhdGUoLTUwKSIgc3Ryb2tlPSIjMTY4MjA4IiBzdHJva2Utd2lkdGg9IjMiIGZpbGw9IiNGRkZGRkYiIGZpbGwtb3BhY2l0eT0iMCIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin-left: 8px; }

.isSelected .actionText {
  font-weight: bold; }

/*
  Hack: To override top: 1px style in ace-icon
  using important here temporarily, it will be remove when ace-icon is fixed
*/
.editorControls {
  padding-top: 12px;
  padding-left: 12px; }

.hackStyle {
  top: 0 !important; }
